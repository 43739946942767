import React, { Fragment, useEffect, useRef, useState } from 'react';
import { useQueryParam } from 'use-query-params';
import _ from 'lodash';

import { toast } from 'react-hot-toast';
import { Tooltip } from '@material-ui/core';
import { useQuery } from 'react-query';
import { useIndividualInvoiceData } from '../../../react-queries/admin-invoices-data/admin-invoices-data.queries';
import { Button } from '../../../components';
import { adminInvoiceService } from '../../../services';
import { CorrespondenceType } from '../../../services/api/user-auth/types';
import { useDownloadFile } from '../../../hooks/global';
import { AdminInvoiceForm } from '../admin-invoice-form/admin-invoice-form';
import { Guid } from '../../../types';
import { getAccountProfiles } from '../../../services/api/admin-profiles/admin-profiles.service';

const IndividualInvoiceScreen: React.FC = () => {
  const [invoiceId] = useQueryParam<string>('Id');
  const individualInvoiceData = useIndividualInvoiceData(invoiceId);
  const [sendInvoiceEmailDisabled, setSendInvoiceEmailDisabled] = useState(false);
  const [sendInvoiceWhatsAppDisabled, setSendInvoiceWhatsAppDisabled] = useState(false);
  const [userId] = useQueryParam<Guid>('userId');

  const resetTimeout = useRef(null);

  const invoiceFormDefaultValues = {
    poNumber: individualInvoiceData?.data?.poNumber,
    additionalInformationKey: individualInvoiceData?.data?.additionalInformationKey,
    additionalInformationValue: individualInvoiceData?.data?.additionalInformationValue,
  };

  const { data: adminAccountProfiledata } = useQuery(
    ['AdminAccountProfileData', userId],
    () => getAccountProfiles(userId),
    {
      enabled: !!userId,
    },
  );

  useEffect(() => {
    return () => {
      clearTimeout(resetTimeout.current);
    };
  }, []);

  const _someInvoiceItemContainsMunicipalityReceiptNumber = () =>
    individualInvoiceData?.data?.invoiceItems
      .flatMap((invoiceItemGroup) =>
        invoiceItemGroup.invoiceItems.map((invoiceItem) => !!invoiceItem.municipalityReceiptNumber),
      )
      .some((municipalityReceiptNumberHasValue) => municipalityReceiptNumberHasValue === true);

  const { download: _downloadInvoiceContentPdf, loading: isLoadingDownloadPdf } = useDownloadFile({
    apiDefinition: () => adminInvoiceService.getInvoicePdf(invoiceId),
    onError: () => toast.error('Could not download invoice'),
  });

  const _sendCorrespondence = (type: CorrespondenceType) => {
    if (type === CorrespondenceType.WhatsApp) {
      setSendInvoiceWhatsAppDisabled(true);
    } else if (type === CorrespondenceType.Email) {
      setSendInvoiceEmailDisabled(true);
    }
    adminInvoiceService
      .sendInvoiceCorrespondence(invoiceId, type)
      .then(() => {
        toast.success('Invoice sent successfully');
        resetTimeout.current = setTimeout(() => {
          if (type === CorrespondenceType.WhatsApp) {
            setSendInvoiceWhatsAppDisabled(false);
          } else if (type === CorrespondenceType.Email) {
            setSendInvoiceEmailDisabled(false);
          }
        }, 5000);
      })
      .catch((e) => {
        toast.error(e.detail);
        if (type === CorrespondenceType.WhatsApp) {
          setSendInvoiceWhatsAppDisabled(false);
        } else if (type === CorrespondenceType.Email) {
          setSendInvoiceEmailDisabled(false);
        }
      });
  };
  const _renderInformationCard = () => {
    return (
      <div className="flex text-sm">
        <AdminInvoiceForm
          defaultValues={invoiceFormDefaultValues}
          displayValues={individualInvoiceData.data}
        />
      </div>
    );
  };

  const _renderTable = () => {
    return (
      <>
        <table>
          <thead className="font-sans font-thin border-none">
            <tr className="bg-table h-16">
              <th>Ticket No</th>
              {_someInvoiceItemContainsMunicipalityReceiptNumber() ? (
                <Tooltip title="Municipality Receipt Number">
                  <th>MRN</th>
                </Tooltip>
              ) : null}
              <th>Reg No</th>
              <th>Reason</th>
              <th>Area</th>
              <th>Offense Date</th>
              <th>Fine Amount</th>
              <th>Other Fees</th>
              <th>Discount</th>
              <th>Total Amount</th>
              <th>Already Paid</th>
              <th>Total Outstanding</th>
            </tr>
          </thead>

          <tbody className="text-sm">
            {_.map(individualInvoiceData?.data?.invoiceItems, (invoiceData) => (
              <Fragment key={invoiceData.invoiceOffender}>
                <tr>
                  <td>({invoiceData?.invoiceOffender})</td>
                </tr>
                {_.map(invoiceData.invoiceItems, (invoiceItemsData) => (
                  <tr className="bg-table" key={invoiceItemsData.fineId}>
                    <td className=" ">{invoiceItemsData?.noticeNumber}</td>
                    {_someInvoiceItemContainsMunicipalityReceiptNumber() ? (
                      <td className="text-center">
                        {invoiceItemsData?.municipalityReceiptNumber ?? '-'}
                      </td>
                    ) : null}
                    <td className=" ">{invoiceItemsData?.vehicleRegistrationNumber}</td>
                    <td className=" ">{invoiceItemsData?.reason}</td>
                    <td className="">{invoiceItemsData?.municipality}</td>
                    <td className=" ">{invoiceItemsData?.offenceDate}</td>
                    <td className=" ">R {invoiceItemsData?.fineAmount}</td>
                    <td className="w-44">
                      <div>Warrant Fee: R {invoiceItemsData?.warrantFee}</div>
                      <div>Enforcement Fee: R {invoiceItemsData?.enforcementOrder}</div>
                      <div>Service Fee: R {invoiceItemsData?.serviceFee}</div>
                    </td>
                    <td className="">R {invoiceItemsData?.discount}</td>
                    <td className=" ">R {invoiceItemsData?.total}</td>
                    <td className=" ">R {invoiceItemsData?.amountPaid}</td>
                    <td className=" ">R {invoiceItemsData?.totalOutstanding}</td>
                  </tr>
                ))}
              </Fragment>
            ))}
          </tbody>
        </table>
      </>
    );
  };
  return (
    <>
      <div className="flex flex-row p-3 mx-14">
        <div className="flex flex-auto items-end">
          <div className="flex text-2xl">Invoice</div>
        </div>
        <div className="flex flex-col gap-2">
          <Button
            onClick={() => _sendCorrespondence(CorrespondenceType.Email)}
            disabled={sendInvoiceEmailDisabled}
            className="bg-secondary hover:bg-secondary-dark text-white text-base self-center w-44"
          >
            Send Email
          </Button>
          <Tooltip
            title={
              !adminAccountProfiledata?.isWhatsAppVerified
                ? 'Client WhatsApp has has not been verified'
                : ''
            }
          >
            <div>
              <Button
                onClick={() => _sendCorrespondence(CorrespondenceType.WhatsApp)}
                disabled={
                  sendInvoiceWhatsAppDisabled || !adminAccountProfiledata?.isWhatsAppVerified
                }
                className="bg-total-green hover:bg-secondary-dark text-white text-base self-center w-44"
              >
                Send WhatsApp
              </Button>
            </div>
          </Tooltip>
          <Button
            onClick={_downloadInvoiceContentPdf}
            isLoading={isLoadingDownloadPdf}
            className="bg-tertiary hover:bg-tertiary-dark text-white text-base self-center w-44"
          >
            Download PDF
          </Button>
        </div>
      </div>
      <div className="border-b border-black mt-3  flex justify-start mr-16 ml-16" />
      <div className="ml-16 text-lg">
        {individualInvoiceData.data ? _renderInformationCard() : <></>}
      </div>
      <div className="mx-14 mt-8">{_renderTable()}</div>
      <div className="flex justify-end mr-14">
        <div className="flex  justify-end text-3xl bg-table h-10">
          <div className="flex mx-2">
            Referral Discount:
            <div className="font-bold mx-2">R {individualInvoiceData?.data?.referralDiscount}</div>
            Total Discount:
            <div className="font-bold mx-2">R {individualInvoiceData?.data?.totalDiscount}</div>
            Total:
            <div className="font-bold ml-2"> R {individualInvoiceData?.data?.subTotal}</div>
          </div>
        </div>
      </div>
    </>
  );
};
export default IndividualInvoiceScreen;
